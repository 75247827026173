@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  padding: var(--modalPadding);
  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderDescription,
.orderHelp {
  font-size: 14px;
  line-height: 150%;
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);
  margin-bottom: 16px;
  @media (--viewportMedium) {
    font-size: 16px;
  }
}
.peopleLooking {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & > svg {
    margin-right: 8px;
  }
  & > span {
    font-size: 14px;
    color: var(--colorBlack);
    font-weight: var(--fontWeightRegular);
    line-height: 110%;
    @media (--viewportMedium) {
      font-size: 16px;
    }
    & > strong {
      font-weight: var(--fontWeightSemiBold);
    }
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-size: 20px;
  line-height: 100%;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey900);
  margin: 0;

  @media (--viewportMedium) {
    padding: 0;
    font-size: 26px;
  }
}
.oldPrice {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey700);
  line-height: 100%;
  font-family: var(--fontFamilySecondary);
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightRegular);
  color: #a7a7a7;
  line-height: 100%;
  font-size: 14px;
  font-family: var(--fontFamilySecondary);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
  }
}
.autherNameRating {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.authorDetails {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px - 17px);
}
.authorLocation {
  font-size: 14px;
  color: var(--colorBlack);
  line-height: 110%;
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilySecondary);
  @media (--viewportMedium) {
    font-size: 16px;
  }
}
.author {
  composes: p from global;
  margin: 12px 0 23px 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: flex;
  margin-right: 17px;
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
}

.orderHeading {
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderTitle {
  font-weight: var(--fontWeightSemiBold);
  color: var(--textColor);
  font-size: 26px;
  line-height: 120%;
  margin-top: 0;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    font-size: 34px;
  }
}

.orderHelp {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 14px 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportLarge) {
    display: none;
  }
  & > button {
    width: auto;
    padding: 10px 24px;
    line-height: 140%;
    min-height: auto;
  }
}
.condition {
  margin-bottom: 11px;
  font-size: 14px;
  color: var(--colorBlack);
  font-weight: var(--fontWeightRegular);
  @media (--viewportMedium) {
    font-size: 16px;
  }
  & > strong {
    font-weight: var(--fontWeightSemiBold);
  }
}
.priceContainer {
  margin-bottom: 20px;
}
.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
  }
}
.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}
.ratingSec {
  margin: 0;
  display: inline-flex;
  align-items: center;
  line-height: 100%;
  margin-left: 12px;
  @media (--viewportMedium) {
    margin-left: 18px;
  }
  & > svg {
    width: 12px;
    height: 12px;
    margin-right: 3px;
    & > path {
      fill: var(--colorAttention);
    }
  }
  & > span {
    color: #605f5f;
    font-size: 12px;
    margin-left: 6px;
  }
}
.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
