@import '../../../styles/customMediaQueries.css';
.megaDropdownMenu {
  width: 90%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  & .megaDropdownMenuLink {
    width: 100%;
    border: none;
    text-align: left;
    color: var(--textColor);
    font-weight: 14px;
    font-weight: var(--fontWeightMedium);
    background-color: var(--colorWhite);
    cursor: pointer;
    transition: var(--transitionStyle);
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > svg {
      fill: none;
    }
    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--colorGrey50);
    }
  }
  & .backLink {
    width: 100%;
    border: none;
    text-align: left;
    color: var(--textColor);
    font-weight: 14px;
    font-weight: var(--fontWeightMedium);
    background-color: var(--colorWhite);
    cursor: pointer;
    transition: var(--transitionStyle);
    padding: 12px 24px;
    display: flex;
    align-items: center;
    & > svg {
      fill: none;
      margin-right: 10px;
    }
  }
}
