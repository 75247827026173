@import '../../../styles/customMediaQueries.css';

.fixedWidthContainer {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* Desktop */
.root {
  position: relative;
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);
}

/* logo */
.logoLink {
  transition: var(--transitionStyleButton);
  padding: 0;
}
.logoLink:hover {
  border-radius: 0;
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}
.quickSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  & > span {
    color: #807e7e;
  }
  & a {
    color: var(--textColor);
    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }
}
.quickSearchLink {
  composes: smallFont from global;
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  text-decoration: inherit;
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 0px 0 12px;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}
.rightLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    &:not(:last-child) {
      margin-right: 14px;
      @media (--viewportLargeWithPaddings) {
        margin-right: 20px;
      }
    }
  }
}
.commonIcons {
  position: relative;
  & > svg {
    fill: none;
    cursor: pointer;
  }
  & .notification {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: var(--textColor);
    color: var(--colorWhite);
    font-weight: var(--fontWeightBold);
    font-size: 12px;
    letter-spacing: 1.2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
.middleLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > * {
    &:not(:last-child) {
      margin-right: 14px;
      @media (--viewportLargeWithPaddings) {
        margin-right: 20px;
      }
    }
  }
}
/* Authentication */

.navLink {
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 12px 0;
  font-size: 14px;
  line-height: 120%;
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);

  &:hover {
    color: var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
    & > svg {
      & path {
        fill: var(--marketplaceColor);
      }
    }
  }
  & > svg {
    width: 18px;
    height: 18px;
  }
  & .inbox {
    position: relative;

    & .notificationDot {
      /* Dimensions */
      width: 7px;
      height: 7px;
      border-radius: 50%;

      /* Position */
      position: absolute;
      top: 2px;
      right: -9px;

      /* Style: red dot */
      background-color: var(--colorFail);

      animation-name: notificationPop;
      animation-duration: 0.1s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;

      animation-timing-function: ease-in;
      opacity: 0;
      transform: scale(0);
    }
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 8px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}
.searchDropdown {
  position: relative;
  & .searchDropdownContentWrapper {
    background-color: var(--colorWhite);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: var(--boxShadowBottom);
    padding: 24px;
    width: 100%;
    & .searchDropdownContent {
      width: 100%;
      background-color: var(--colorWhite);
      & .searchLink {
        max-width: 450px;
        margin: 0 auto 16px;
        width: 100%;
        & .topbarSearchWithLeftPadding {
          border: solid 1px var(--textColor);
          padding: 10px;
          border-radius: var(--borderRadiusLarge);
        }
      }
    }
  }
}
