@import '../../styles/customMediaQueries.css';

.allItemsAreSelected {
  display: inline-block;
  text-transform: capitalize;
}

.allItemsAreSelected + .allItemsAreSelected::before {
  display: inline-block;
  white-space: pre;
  content: ', ';
}

.attention {
  & > div {
    & > div {
      /* border: 1px solid var(--matterColorNegative) !important; */
      /* padding: 5px 10px; */
      /* width: 295px; */
      margin-bottom: 6px;
    }
  }
}

.selectContainer {
  position: relative;

  & > div ~ div {
    position: relative;
    /* width: 20px; */
    display: block;
  }
}

.success {
  & > div {
    & > div {
      border: 1px solid var(--successColor) !important;

      &:focus,
      &:hover {
        border: 1px solid var(--successColor) !important;
      }
    }
  }
}

.error {
  & > div {
    & > div {
      border: 1px solid var(--failColor) !important;

      &:focus,
      &:hover {
        border: 1px solid var(--failColor) !important;
      }
    }
  }
}

:global(.react-select__menu) {
  position: absolute;
  z-index: 1000;
}

:global(.react-select__multi-value__label) {
  margin: 0 0 0 5px;
}

:global(.react-select__placeholder) {
  padding: 0px !important;
  margin: 0px !important;
}

.multiSelectLabel {
  font-size: 20px;
}

.multiselectValueLabel {
  font-size: 20px;
}

.categorySelect label {
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.72px;
  text-align: left;
  color: #484848;
}

.hideLabel {
  color: white !important;
}
