@import '../../styles/customMediaQueries.css';

.fixedWidthContainer {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}
.footer {
  background-color: var(--marketplaceColor);
  padding: 30px 0 30px;
  @media (--viewportMedium) {
    padding: 50px 0 30px;
  }
  & .topContent {
    display: flex;
    flex-direction: column;
    @media (--viewportMedium) {
      flex-direction: row;
    }
    & .leftSec {
      width: 100%;
      margin-bottom: 40px;
      @media (--viewportMedium) {
        width: 40%;
        margin-bottom: 0;
      }
      @media (--viewportLarge) {
        width: 50%;
      }
      & > h2 {
        color: var(--colorWhite);
        font-weight: var(--fontWeightMedium);
        font-size: 34px;
        line-height: 120%;
        margin: 0 0 10px 0;
      }
      & > p {
        font-size: 16px;
        line-height: 24px;
        color: var(--colorWhite);
        font-weight: var(--fontWeightRegular);
        margin: 0 0 10px 0;
        font-family: var(--fontFamilySecondary);
      }
      & .newsLetterSec {
        display: flex;
        align-items: center;

        width: 100%;
        border-bottom: solid 1px var(--colorWhite);
        margin-top: 20px;
        @media (--viewportMedium) {
          max-width: 400px;
        }
        & > svg {
          width: 24px;
          & path {
            fill: var(--colorWhite);
          }
        }
        & > input {
          width: calc(100% - 24px);
          font-size: 16px;
          color: var(--colorWhite);
          font-weight: var(--fontWeightRegular);
          border: none;
          border-radius: 0;
          box-shadow: none;
          background-color: transparent;
          padding: 10px;
          &::placeholder {
            opacity: 1;
            color: var(--colorWhite);
          }
        }
        & .subscribeBtn {
          background-color: transparent;
          border: none;
          color: var(--colorWhite);
          font-size: 16px;
          font-weight: var(--fontWeightMedium);
          padding: 0;
          box-shadow: none;
          cursor: pointer;
          &:hover {
          }
        }
      }
    }
    & .rightSec {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
      @media (--viewportSmall) {
        flex-direction: row;
      }
      @media (--viewportMedium) {
        padding-left: 32px;
        width: 60%;
      }
      @media (--viewportLarge) {
        width: 50%;
        padding-left: 0;
      }
      & .quickLinks {
        width: 100%;
        margin-bottom: 24px;
        @media (--viewportSmall) {
          width: calc(100% / 3);
          margin-bottom: 0;
        }
        & > h2 {
          font-size: 14px;
          line-height: 24px;
          color: var(--colorWhite);
          font-weight: var(--fontWeightMedium);
          margin: 0 0 10px 0;
        }
        & > a {
          font-size: 14px;
          font-weight: var(--fontWeightRegular);
          line-height: 24px;
          display: inline-block;
          color: var(--colorWhite);
          font-family: var(--fontFamilySecondary);
          width: 100%;
          margin: 0 0 10px 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  & .bottomContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: column;
    @media (--viewportSmall) {
      flex-direction: row;
      margin-top: 70px;
    }
    & .socialMediaLinks {
      margin-bottom: 10px;
      @media (--viewportSmall) {
        margin-bottom: 0;
      }
      & > a {
        background-color: var(--colorWhite);
        border: solid 1px var(--colorWhite);
        border-radius: 100px;
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: var(--transitionStyle);
        &:not(:last-child) {
          margin-right: 16px;
        }
        &:hover {
          transition: var(--transitionStyle);
          border-color: var(--colorWhite);
          background-color: var(--marketplaceColorDark);
          & > svg {
            & path {
              fill: var(--colorWhite);
              stroke: none;
            }
          }
        }
        & > svg {
          fill: none;
        }
      }
    }
    & .copyRight {
      color: var(--colorWhite);
      font-size: 14px;
      font-weight: var(--fontWeightRegular);
      margin-bottom: 10px;
      font-family: var(--fontFamilySecondary);
      @media (--viewportSmall) {
        margin-bottom: 0;
      }
    }
  }
}
.countryCurrency {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    &:not(:last-child) {
      margin-right: 13px;
    }
  }
  & .languageSelect,
  & .currencySelect {
    font-size: 14px;
    font-weight: var(--fontWeightMedium);
    color: var(--colorWhite);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & .flag {
      width: 20px;
      height: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      & > img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & > svg {
      width: 16px;
      height: 20px;
      margin-left: 5px;

      & path {
        fill: var(--colorWhite);
      }
      & rect {
        fill: var(--colorWhite);
      }
    }
  }
}
