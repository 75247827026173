@import '../../../styles/customMediaQueries.css';
.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: block !important;
  position: relative;
  @media (--viewportLarge) {
    padding-bottom: 347px;
  }
}

.main {
  display: block;
}
.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.footer {
  position: relative;
  width: 100%;
  @media (--viewportLarge) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
