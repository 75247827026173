@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}
.menuOpened {
  &::after {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colorGrey700);
    opacity: 0.5;
    content: '';
    z-index: 0;
  }
}
.container {
  /* Size */
  width: 100%;
  position: relative;
  z-index: 1;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    display: none;
  }
  & .topbarContentMobile {
    /* Layout for child components */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: var(--topbarHeight);
    align-items: center;
    padding: 10px 16px;
    @media (--viewportMedium) {
      height: var(--topbarHeightDesktop);
    }
    & .leftSec {
      display: flex;
      align-items: center;
    }
    & .rightSec {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & .cart {
        border: none;
        display: inline-flex;
        align-items: center;
        padding: 0;
        & .notification {
          width: 20px;
          height: 20px;
          border-radius: 100px;
          background-color: var(--textColor);
          color: var(--colorWhite);
          font-weight: var(--fontWeightBold);
          font-size: 12px;
          letter-spacing: 1.2px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
      }
    }
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  position: relative;
  padding: 0;
  width: 33px;
}
.searchMenu {
  padding: 0;
  width: 30px;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 23px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 44px;
  & > form {
    /* border: solid 1px var(--textColor);
    border-radius: var(--borderRadiusLarge);
    padding: 0 15px; */
    margin-bottom: 16px;
    &:hover {
      /* border-color: var(--textColor); */
    }
  }
}

.mobileHelp {
  color: var(--colorGrey300);

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: relative;
  margin: 10px 0;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: 100vh;
    height: 100%;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}
.modalContainerSearchForm {
  composes: modalContainer;
  @media (--viewportMedium) {
    padding: var(--modalPadding);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}
.topbarTop {
  background-color: var(--marketplaceColor);
  @media (--viewportMedium) {
    padding: 8px 52px;
  }
  & .fixedWidthContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    @media (--viewportMedium) {
      flex-direction: row;
    }
    & > p {
      color: var(--colorWhite);
      font-size: 14px;
      font-weight: var(--fontWeightMedium);
      text-align: center;
      margin: 0;
      flex-grow: 1;
      padding: 8px 24px;
      cursor: pointer;
      @media (--viewportMedium) {
        padding: 0;
      }
      & > a {
        /* text-decoration: underline; */
        color: var(--colorWhite);
        font-size: 14px;
        font-weight: var(--fontWeightMedium);
      }
    }
    & .countryCurrency {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2b2b2b;
      width: 100%;
      padding: 6px 24px;
      @media (--viewportMedium) {
        background-color: transparent;
        justify-content: flex-end;
        width: auto;
        padding: 0;
      }
      & > * {
        &:not(:last-child) {
          margin-right: 13px;
        }
      }
      & .languageSelect,
      & .currencySelect {
        font-size: 14px;
        font-weight: var(--fontWeightMedium);
        color: var(--colorWhite);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & .flag {
          width: 20px;
          height: 15px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
          & > img {
            max-width: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        & > svg {
          width: 16px;
          height: 20px;
          margin-left: 5px;

          & path {
            fill: var(--colorWhite);
          }
          & rect {
            fill: var(--colorWhite);
          }
        }
      }
    }
  }
}

.fixedWidthContainer {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
}

.quickSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  & > span {
    color: #807e7e;
  }
  & a {
    color: var(--textColor);
    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }
}
.quickSearchLink {
  composes: smallFont from global;
}
