@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  transition: var(--transitionStyle);
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: var(--transitionStyle);
    box-shadow: var(--boxShadowListingCard);
  }
}
.searchPageListingCard {
  & .aspectRatioWrapper,
  & .rootForImage {
    border-radius: 0;
  }
  & .mainInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & .title {
      font-weight: var(--fontWeightMedium);
      font-family: var(--fontFamily);
    }
    & .authorInfo {
      justify-content: flex-start;
      align-items: flex-start;
    }
    & .otherInfo {
      font-size: 12px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorBlack);
    }
    & .location {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorBlack);
      & > img {
        width: 24px;
        height: 14px;
        max-width: 100%;
        margin-right: 10px;
      }
    }
  }
}
.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  position: relative;
  & > div {
    padding-bottom: 100% !important;
  }
  & .favoriteIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background-color: var(--colorWhite);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 25%);
    padding: 2px;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    & > svg {
      /* fill: none; */
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  composes: listingCardPriceValue from global;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  composes: listingCardTitle from global;
}

.authorInfo {
  composes: listingCardAuthorInfo from global;
}
.soldby {
  composes: soldby from global;
}
.authorName {
  composes: authorName from global;
}
.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
