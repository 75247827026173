.bottomNavbar {
  display: none;
  justify-content: space-around;
  position: fixed;
  border-radius: 10px;
  margin: 0 auto;
  width: 95%;
  left: 0;
  right: 0;
  bottom: 12px;
  background-color: #fff;
  z-index: 10;
  border-top: solid 1px #ddd;
  /* box-shadow: 0px 0px 40px #c9c9c9; */
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;

  @media (max-width: 767px) {
    display: flex;
    padding: 11px 2px 17px 2px;
  }

  & > span {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10px 10px 10px;
    /* opacity: 0.6; */

    @media (max-width: 480px) {
      padding: 8px 8px 0px;
      font-size: 13px;
    }

    @media (max-width: 375px) {
      font-size: 11px;
    }

    &.active {
      opacity: 1;
    }

    & > svg,
    & > img {
      width: 30px;
      height: 30px;
      fill: none;
    }
  }
}
