@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  display: flex;

  @media (--viewportLarge) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.termsLink {
  composes: marketplaceModalHelperText from global;
  margin-top: 15px;
  color: var(--textColor);
  text-align: center;
}

.orText {
  text-align: center;
  composes: marketplaceModalHelperText from global;
  color: var(--textColor);
  position: relative;
  margin: 20px 0;

  &::before {
    content: '';
    border: dashed 1px var(--colorGrey100);
    width: 100%;
    height: 1px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
  }

  & > span {
    background-color: var(--colorWhite);
    padding: 10px 20px;
    display: inline-block;
    z-index: 1;
    position: relative;
  }
}

.signupContent {
  width: 100%;
  padding: var(--modalPadding);

  @media (--viewportLarge) {
    width: 50%;
    padding: var(--modalPaddingMedium);
  }

  & > h2 {
    text-align: center;
    font-size: 30px;
    font-weight: var(--fontWeightBold);
    color: var(--textColor);
    margin-bottom: 40px;
    margin-top: 0;
    line-height: 100%;
  }
}

.signupInfoSec {
  background-color: var(--marketplaceColor);
  padding: var(--modalPadding);
  width: 100%;
  display: none;

  @media (--viewportLarge) {
    display: block;
    width: 50%;
    padding: var(--modalPaddingMedium);
  }

  & .infoBlock {
    display: flex;
    flex-direction: column;
    color: var(--colorWhite);
    font-size: 18px;
    font-weight: var(--fontWeightRegular);
    padding: 0;

    & > svg {
      margin-bottom: 10px;
      width: 40px;
      height: 40px;

      & path {
        fill: var(--colorWhite);
      }
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.errorWrapper {
  margin-top: 14px;
  line-height: 16px;
  color: red;
}
