@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
  & > img {
    width: 180px;
    @media (--viewportSmall) {
      width: auto;
    }
  }
}
