@import '../../../styles/customMediaQueries.css';
.megaDropdownMenu {
  max-width: 880px;
  width: 100%;
  border-radius: var(--borderRadiusLarge);
  margin: 0 auto;
  background-color: var(--colorWhite);
  overflow: hidden;
  box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 13%);
  position: absolute;
  top: calc(100% - 8px);
  left: 50%;
  transform: translateX(-50%);
}
.navbarContainer {
  display: flex;
  gap: 24px;
  background-color: var(--colorGrey);
  align-items: center;
  justify-content: center;
  & .navbarCategories {
    color: var(--colorWhite);
    font-family: var(--fontFamilySecondary);
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      cursor: pointer;
      color: var(--bgColor);
    }
  }
}

.subCategoriesWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--colorWhite);
  & .verticalNavbar {
    display: grid;
    background-color: var(--greyBG);
    width: 25%;
    padding: 8px;
    & .verticalNavbarLink {
      font-weight: var(--fontWeightMedium);
      font-size: 14px;
      color: var(--textColor);
      padding: 6px 14px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      transition: var(--transitionStyle);
      border-radius: var(--borderRadiusMedium);
      &:hover {
        transition: var(--transitionStyle);
        color: var(--marketplaceColor);
        background-color: var(--colorWhite);
        cursor: pointer;
        & > svg {
          fill: none;
          & path {
            stroke: var(--marketplaceColor);
          }
        }
      }
      & > svg {
        fill: none;
        & path {
          stroke: var(--textColor);
        }
      }
    }
  }
  & .verticalNavbarContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    padding: 15px 18px;
    & .subcategories {
      width: 60%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 40px;
      & .categoryLink {
        color: var(--colorBlack);
        font-size: 14px;
        font-weight: var(--fontWeightMedium);
        transition: var(--transitionStyle);
        &:hover {
          transition: var(--transitionStyle);
          color: var(--marketplaceColor);
          text-decoration: none;
        }
      }
    }
    & .categoryImg {
      flex-grow: 1;
      position: relative;
      width: 40%;
      height: 168px;
      align-self: flex-start;
      & > img {
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.activeNav {
  transition: var(--transitionStyle);
  cursor: pointer;
  color: var(--bgColor);
}
