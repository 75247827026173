@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-bottom: 30px;
  & > button {
    margin-bottom: 10px;
  }
  & > span {
    cursor: pointer;
    &:hover {
      color: var(--marketplaceColor);
    }
    &:first-of-type {
      margin-right: 24px;
    }
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
.termsLink {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 0;
  background-color: var(--colorGrey50);
  color: var(--textColor);
  padding: 20px 24px;
  text-align: center;
}
.loginContent {
  padding: var(--modalPadding);
  padding-bottom: 0;
  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
    padding-bottom: 0;
  }
  & > h2 {
    text-align: center;
    font-size: 30px;
    font-weight: var(--fontWeightBold);
    color: var(--textColor);
    margin-bottom: 40px;
  }
}

.errorWrapper {
  margin-top: 14px;
  line-height: 16px;
  color: red;
}
